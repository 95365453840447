import React from "react";

const TopPagesListCard = ({ card, value, onClick, selectedCard }) => {
  return (
    <div
      className={`list-card ${selectedCard && selectedCard.index === card.index ? "card-selected" : ""}`}
      onClick={() => onClick(card)}
    >
      <div className="list-card-icon">
        <i className={card.icon}></i>
      </div>
      <div className="list-card-content">
        <a className="list-card-title" href={card.url} target="_blank" rel="noopener noreferrer">
          {card.title}
        </a>
      </div>
      <div className="list-card-value">{value}</div>
    </div>
  );
};

export default TopPagesListCard;
