import React, { useRef } from "react";

const FilterDate = ({ register, registerLabel, onChange, ...props }) => {
  const datePickerRef = useRef(null);
  const handleOpen = () => {
    const inputDateElement = document.getElementById(registerLabel);
    inputDateElement.showPicker();
  };

  return (
    <div
      className="input-group"
      onClick={handleOpen}
      style={{ cursor: "pointer" }}
    >
      <span className="input-group-label">
        <i className="icon icon-calendar"></i>
      </span>
      <input
        ref={datePickerRef}
        type="date"
        id={registerLabel}
        className="date-input filter-select"
        data-date-format="mm/dd/yyyy"
        style={{ cursor: "pointer" }}
        {...register(registerLabel, { onChange })}
      />
    </div>
  );
};

export default FilterDate;
