import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import SearchFunnels from "../SearchFunnels/SearchFunnels";
import TopPagesCard from "./components/TopPagesCard";
import TopPagesListCard from "./components/TopPagesListCard";
import TopPagesNavigation from "./components/TopPagesNavigation";
import LayersModal from "./components/LayersModal";
import LayersSelect from "./components/LayersSelect";
import FunnelChart from "./components/FunnelChart";
import FilterSwitch from "../SearchFunnels/FilterSwitch";
import topPagesService from "../../../../services/TopPagesService";
import queryBuilderService from "../../../../services/QueryBuilderService";
import { Tooltip } from "@mui/material";

const compareCards = (a, b) => b.amount - a.amount;

const buildQueryFilters = (queryFilters) => {
  let hash;

  queryFilters = queryFilters.filter((rule) => {
    if (!rule.option) {
      return false;
    }
    return !(rule.requiresValue && (!rule.value || rule.value?.length === 0));
  });

  try {
    hash = queryBuilderService.convertToRulesParams(queryFilters, {
      addField: true,
    });
  } catch (e) {
    console.error(e);
    return "";
  }

  return JSON.stringify(hash);
};

const formatAmount = (amount) => {
  return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(amount);
};

const TopPages = ({ topPage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [layersOptions, setLayersOptions] = useState([]);
  const [selectedLayer, setSelectedlayer] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [topPagesOptions, setTopPagesOptions] = useState([]);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [cards, setCards] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [filters, setFilters] = useState(null);

  const [currentCardsNumber, setCurrentCardsNumber] = useState(12);
  const [opportunitySwitch, setOpportunitySwitch] = useState("amount");
  const [layout, setLayout] = useState("default");

  const latestFilters = useRef(null);
  const [filtersToolTip, setFiltersToolTip] = useState("");
  const [viewBy, setViewBy] = useState("");
  const [total, setTotal] = useState(0);
  const [totalEngagements, setTotalEngagements] = useState(0);
  const [topByPluralized, setTopByPluralized] = useState(0);

  useEffect(() => {
    axios.get("/funnels/top_pages/metadata").then((response) => {
      if (response.data) {
        const layers = response.data.account_layers
          .sort((a, b) => a.order - b.order)
          .map((layer, idx) => ({
            ...layer,
            order: idx,
          }));
        setLayersOptions(layers);
        setSelectedlayer(layers[0]);

        setFilterOptions(response.data.child_filters_options);
        setTopPagesOptions(response.data.top_pages);
      }
    });
  }, []);

  useEffect(() => {
    if (filters) {
      const finalFilter = {};
      if (filters.queryFilter) {
        finalFilter["setting[filter]"] = buildQueryFilters(filters.queryFilter);
      }
      finalFilter["top_by"] = selectedLayer.name.toLowerCase();
      if (selectedLayer?.type === "opportunity") {
        finalFilter["waterfall_type"] =
          opportunitySwitch === "count" ? "COUNT" : "AMOUNT";
      }
      finalFilter["dates[date_range]"] = filters.dateRange;
      finalFilter["setting[mode]"] = filters.attributionMode;
      finalFilter["all_contacts"] = filters.aba;
      if (filters.dateMonthRange) {
        finalFilter["dates[month]"] = filters.dateMonthRange;
      }
      if (filters.dateRangeYear) {
        finalFilter["dates[year]"] = filters.dateRangeYear;
      }
      if (filters.dateRangeStart && filters.dateRangeEnd) {
        finalFilter["dates[custom_start]"] = filters.dateRangeStart;
        finalFilter["dates[custom_end]"] = filters.dateRangeEnd;
      }

      latestFilters.current = finalFilter;

      setIsLoading(true);

      axios
        .get(`/funnels/top_pages/top_page_data/${topPage}`, {
          params: finalFilter,
        })
        .then((response) => {
          if (response.data.cards) {
            setCards(response.data.cards.sort(compareCards));
            setCurrentCardsNumber(12);
          }
          setLayout(response.data.layout); // list or default
          setChartData(response.data.chart_data);
          var filterToolTip = "Filters: " + response.data.filters.filters_formatted;
          setFiltersToolTip(filterToolTip);
          setViewBy(response.data.filters.setting.view_by);
          setTotal(response.data.total);
          setTopByPluralized(response.data.top_by_pluralized);
          setTotalEngagements(response.data.total_engagements);
        })
        .catch((er) => {
          console.error(er);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [filters, selectedLayer, opportunitySwitch]);

  const handleSearch = (filters) => {
    setIsLoading(true);
    setFilters(filters);
  };

  const handleLoadMore = () => {
    const nextLimit = currentCardsNumber + 12;
    setCurrentCardsNumber(nextLimit > cards.length ? cards.length : nextLimit);
  };

  const renderDefaultLayout = () => {
    return (
      <div className={"cards-grid" + (isLoading ? " updating" : "")}>
        {isLoading ? (
          <div className="loading-state">
            <p>Loading data...</p>
          </div>
        ) : cards.length > 0 ? (
          cards.slice(0, currentCardsNumber).map((card, index) => (
            <TopPagesCard
              key={`card-${index}`}
              card={{ ...card, index }}
              value={
                [
                  selectedLayer.type === "opportunity" &&
                  opportunitySwitch === "amount"
                    ? "$"
                    : "",
                    formatAmount(card.amount),
                ].join("")}
              onClick={() => {
                setSelectedCard(card);
                setIsCardModalOpen(true);
              }}
              selectedCard={selectedCard}
            />
          ))
        ) : (
          <div className="empty-state">
            <p>No data available. Please adjust your filters or try again later.</p>
          </div>
        )}
      </div>
    );
  };

  const renderListLayout = () => {
    return (
      <div className={"list-cards-grid" + (isLoading ? " updating" : "")}>
        {isLoading ? (
          <div className="loading-state">
            <p>Loading data...</p>
          </div>
        ) : cards.length > 0 ? (
          cards.slice(0, currentCardsNumber).map((card, index) => (
            <TopPagesListCard
              key={`card-${index}`}
              card={{ ...card, index }}
              value={[
                selectedLayer.type === "opportunity" &&
                opportunitySwitch === "amount"
                  ? "$"
                  : "",
                  formatAmount(card.amount),
              ].join("")}
              onClick={() => {
                setSelectedCard(card);
                setIsCardModalOpen(true);
              }}
              selectedCard={selectedCard}
            />
          ))
        ) : (
          <div className="empty-state">
            <p>No data available. Please adjust your filters or try again later.</p>
          </div>
        )}
      </div>
    );
  };

  if (!selectedLayer) {
    return null;
  }

  const chartLabel = selectedLayer?.type === "opportunity" && opportunitySwitch === "count" ? "Count" : "Amount";

  return (
    <div className="top-pages-container">
      <div className="top-pages-navigation-container">
        <TopPagesNavigation options={topPagesOptions} />
      </div>
      <div className="top-pages-content-container">
        <h2 className="top-pages-title">
          <i className="icon icon-dr-bar-chart-o"></i>
          Campaign Results:{" "}
          <strong>{topPagesService.transformTopPageString(topPage)}</strong>
          <Tooltip title={filtersToolTip}>
            <i className="icon icon-information-outline" style={{ marginLeft: 8 }} />
          </Tooltip>
        </h2>
        <div className="top-pages-subtitle">
          <span>Results from people that engaged in the given time-frame.</span>
        </div>
        <SearchFunnels
          onClick={handleSearch}
          filterOptions={filterOptions}
          dateRangeLabel="Engaged Date Range"
          isLoading={isLoading}
        />
        <div className="top-by-container">
          <div style={{ marginRight: 32 }}>
            <span>Top results by</span>
            {layersOptions.length > 0 && (
              <LayersSelect
                options={layersOptions}
                onSelect={setSelectedlayer}
                value={selectedLayer}
              />
            )}
          </div>
          <div>
            {selectedLayer && selectedLayer.type === "opportunity" && (
              <FilterSwitch
                options={[
                  {
                    title: "Amount",
                    value: "amount",
                  },
                  {
                    title: "Count",
                    value: "count",
                  },
                ]}
                value={opportunitySwitch}
                setValue={(value) => setOpportunitySwitch(value)}
              />
            )}
          </div>
          <div>
            <span>viewing by {viewBy}</span>
          </div>
        </div>

        {layout === "default" ? renderDefaultLayout() : renderListLayout()}

        {currentCardsNumber < cards.length && (
          <div className="load-more">
            <span onClick={handleLoadMore}>
              Load more (showing {currentCardsNumber} of {cards.length})
            </span>
          </div>
        )}
        <div className="top-pages-total">
          <span>
            <b>{total}</b> Total&nbsp;  
            {selectedLayer.name === 'Engaged' ? 
              'Engagements' : 
              <>
                {topByPluralized}&nbsp;
                from&nbsp;
                <strong>{totalEngagements}</strong>
                Engagements
              </>
            }
          </span>
        </div>
        {chartData && chartData.length > 0 && (
          <FunnelChart selectedLayer={selectedLayer} data={chartData} label={chartLabel} />
        )}
      </div>

      {selectedCard && (
        <LayersModal
          isOpen={isCardModalOpen}
          onOpen={setIsCardModalOpen}
          selectedCard={selectedCard}
          onSelectedCard={setSelectedCard}
          selectedLayer={selectedLayer}
          filters={latestFilters.current}
          topPage={topPage}
          chartLabel={chartLabel}
        />
      )}
    </div>
  );
};

export default TopPages;
