const topPagesService = {
  transformTopPageString: (string) => {
    return string
      .split("_")
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(" ");
  },

  getDetailLinkFromCard: (card) => {
    const detailNameLink = card["detail_link"].split("/");
    return detailNameLink[detailNameLink.length - 1];
  },
};

export default topPagesService;
