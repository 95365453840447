import { layersColors } from "../../../../../utils/colors";
import React, { useState } from "react";
const LayersSelect = ({ options, onSelect, value }) => {
  const [isOpen, setIsOpen] = useState(false);

  window.addEventListener("click", (evt) => {
    if (!document.getElementById("select-container").contains(evt.target)) {
      setIsOpen(false);
    }
  });

  return (
    <div
      id="select-container"
      className="layers-dropdown"
      onClick={() => setIsOpen(true)}
    >
      <div className="layers-dropdown-btn-container">
        <button
          className={`dropbtn chart-hdr ${
            layersColors[value.order % layersColors.length]
          }`}
        >
          {value.name.toUpperCase()}
        </button>
        <i className="fa fa-caret-down"></i>
      </div>
      <div className={`layers-dropdown-content ${isOpen ? "isOpen" : ""}`}>
        {options.map((option, index) => (
          <a
            key={index}
            onClick={(evt) => {
              onSelect(option);
              setIsOpen(false);
              evt.stopPropagation();
            }}
          >
            {option.name.toUpperCase()}
          </a>
        ))}
      </div>
    </div>
  );
};

export default LayersSelect;
