import React, { useEffect, useState } from "react";
import QueryBuilder from "../../../../components/QueryBuilder/QueryBuilder";
import queryBuilderService from "../../../../services/QueryBuilderService";
import axios from "axios";
import Cookies from "js-cookie"; // Make sure to import js-cookie

const defaultOperators = [
  { value: "equals", label: "Equals", requiresValue: true },
  { value: "does_not_equal", label: "Does Not Equals", requiresValue: true },
  { value: "contains", label: "Contains", requiresValue: true },
  {
    value: "does_not_contain",
    label: "Does Not Contain",
    requiresValue: true,
  },
  { value: "is_empty", label: "Is Empty", requiresValue: false },
  { value: "is_not_empty", label: "Is Not Empty", requiresValue: false },
];

const TopPagesFilter = ({ topPagesFilters }) => {
  const [rules, setRules] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [fieldOptions, setFieldOptions] = useState({});

  useEffect(() => {
    if (topPagesFilters) {
      try {
        const initialFilters = JSON.parse(topPagesFilters);
        const convertedFilters = queryBuilderService.convertFromRulesParams(
          initialFilters.rules,
          filterOptions.map(option => ({
            display_value: option.display_value,
            query_key: option.query_key,
          }))
        );
        setRules(convertedFilters);
      } catch (error) {
        console.error("Failed to parse topPagesFilters:", error);
      }
    }
  }, [topPagesFilters, filterOptions]);

  useEffect(() => {
    axios.get("/funnels/top_pages/metadata").then((response) => {
      if (response.data) {
        setFilterOptions(response.data.child_filters_options);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedQuery) {
      const newFieldOptions = { ...fieldOptions };
      const queryString = selectedQuery.value.replaceAll(" ", "+");
      axios
        .get(`/funnels/top_pages/filter_options/${queryString}`)
        .then((response) => {
          if (response) {
            newFieldOptions[selectedQuery.value] = response.data.map(
              (option) => option.name
            );
            setFieldOptions(newFieldOptions);
          }
        });
    }
  }, [selectedQuery]);

  const onQueryChange = (updatedRules) => {
    setRules(updatedRules);
    const validQueries = queryBuilderService.filterValid(updatedRules);

    const jsonRules = queryBuilderService.convertToRulesParams(validQueries, {
      addField: true,
    });

    if (jsonRules.rules.length === 0) {
      Cookies.remove("local_filter");
    } else {
      Cookies.set("local_filter", JSON.stringify(jsonRules), { expires: 1 });
    }
  };

  const queryOptions = filterOptions.map((option) => ({
    label: option.display_value,
    name: option.query_key,
    type: "text",
    operators: defaultOperators,
  }));

  return (
    <QueryBuilder
      options={queryOptions}
      onQueryChange={onQueryChange}
      fieldOptions={fieldOptions}
      defaultQuery={rules} // Set defaultQuery to the rules state
      removeInitialRule
      onChange={(selectedFilter) => {
        setSelectedQuery(selectedFilter);
      }}
    />
  );
};

export default TopPagesFilter;
