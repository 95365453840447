import React from "react";

const TopPagesCard = ({ card, value, onClick, selectedCard }) => {
  return (
    <div className="top-pages-card-container" onClick={() => onClick(card)}>
      <div>
        <div
          className={`top-pages-card-inner-container ${
            selectedCard && selectedCard.index === card.index
              ? `card-selected`
              : ""
          }`}
        >
          <i className={card.icon}></i>
          <span>{card.title}</span>
        </div>
      </div>
      <span className="card-title">{value}</span>
    </div>
  );
};

export default TopPagesCard;
