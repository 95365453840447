import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { layersColorsHex } from "../../../../../utils/colors";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const FunnelChart = ({ selectedLayer, data, label }) => {
  const chartData = useMemo(() => {
    if (data) {
      return {
        labels: data.map((el) => el[0]),
        datasets: [
          {
            label: label,
            data: data.map((el) => Math.round(el[1])),
            fill: true,
            borderColor: selectedLayer
              ? layersColorsHex[selectedLayer.order % layersColorsHex.length]
              : "rgb(255, 99, 132)",
            backgroundColor: selectedLayer
              ? layersColorsHex[selectedLayer.order % layersColorsHex.length] +
                "80"
              : "rgb(255, 99, 132, 0.5)",
            tension: 0.4,
          },
        ],
      };
    }
    return {};
  }, [data, selectedLayer, label]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label === "Amount" && selectedLayer.type === "opportunity") {
              label += ": $" + context.raw;
            } else {
              label += ": " + context.raw;
            }
            return label;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="chart-container">
      <Line options={options} data={chartData} />
    </div>
  );
};
export default FunnelChart;
