let id = 505505;

const queryBuilderService = {
  filterValid: (query) => {
    return (
      query?.filter((rule) => {
        if (!rule.option) {
          return false;
        }
        if (rule.requiresValue && (!rule.value || rule.value?.length === 0)) {
          return false;
        }
        return true;
      }) || []
    );
  },

  convertToRulesParams: (query, { addField = false } = {}) => {
    return {
      condition: "AND",
      rules: query.map((rule) => {
        return {
          id: rule.option,
          operator: rule.operator,
          field: addField ? rule.option : undefined,
          value: rule.requiresValue
            ? queryBuilderService.parseValueByType(rule.value, rule.type)
            : undefined,
          type: rule.type === "text" ? "string" : rule.type,
        };
      }),
    };
  },
  convertFromRulesParams: (rulesParams, options) => {
    return rulesParams.map((rule) => {
      const option = options.find((opt) => opt.name === rule.id);
      const operator = option.operators.find(
        (opr) => opr.value === rule.operator
      );

      return {
        id: ++id,
        option: rule.id,
        operator: rule.operator,
        value: operator.requiresValue
          ? queryBuilderService.parseValueFromRulesParams(
              rule.value,
              option.type
            )
          : "",
        requiresValue: operator.requiresValue,
        type: option.type,
        operators: option.operators,
      };
    });
  },

  parseValueByType: (value, type) => {
    if (type === "text") {
      if (!value) {
        return "";
      }
      return value.map((val) => val.value).join(";");
    }
    if (type === "date") {
      return value;
    }
  },

  parseValueFromRulesParams: (value, type) => {
    if (type === "text") {
      return value.split(";").map((val) => ({ value: val, label: val }));
    }
    if (type === "date") {
      return value;
    }
  },

  parseRuleOperator: (operator) => {
    switch (operator) {
      case 'equals':
        return '=';
      case 'does_not_equal':
        return '!=';
      case 'contains':
        return 'contains';
      case 'does_not_contain':
        return 'does not contain';
      case 'is_empty':
        return 'is empty';
      case 'is_not_empty':
        return 'is not empty';
      default:
        return operator;
    }
  },

  humanReadableRules: (rules) => {
    let groupedRules = rules.reduce((acc, rule) => {
      let key = rule.field || rule.id; 
      let operator = queryBuilderService.parseRuleOperator(rule.operator);
      
      if (!acc[key]) {
        acc[key] = { operator: operator, values: [] };
      }

      acc[key].values.push(rule.value);

      return acc;
    }, {});

    return Object.entries(groupedRules)
      .map(([key, { operator, values }]) => {
        let valuesString = values.join(', ');
        if (operator === 'is empty' || operator === 'is not empty') {
          // For 'is_empty' and 'is_not_empty', value is not needed
          return `${key} ${operator}`;
        } else {
          // For other operators, include the values in the output
          return `${key} ${operator} ${valuesString}`;
        }
      })
      .join('; ');
  },
};

export default queryBuilderService;
