import React from "react";

const FilterSwitch = ({ title, options, value, setValue }) => {
  return (
    <>
      {title && <div>{title}</div>}
      <div
        className="switch-toggle callout"
        id="mode_toggle"
        style={{ minWidth: 150 }}
      >
        {options.map((option, index) => (
          <React.Fragment key={option.title}>
            <input
              className="filter-select"
              type="radio"
              readOnly
              checked={value === option.value}
            />
            <label
              onClick={() => {
                setValue(option.value);
              }}
            >
              {option.title}
            </label>
          </React.Fragment>
        ))}
        <a className="button primary"></a>
      </div>
    </>
  );
};

export default FilterSwitch;
