import React from "react";

const FilterSelect = ({
  options,
  register,
  registerLabel,
  onChange,
  ...props
}) => {
  return (
    <select
      className="filter-select"
      {...register(registerLabel, { onChange })}
      {...props}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

export default FilterSelect;
