import React, { useMemo } from "react";

const TopPagesNavigation = ({ options }) => {
  const navigationOptions = useMemo(() => {
    if (options.length === 0) return [];

    // First, sort the options by category_order and page_order
    const sortedOptions = [...options].sort((a, b) => {
      if (a.category_order === b.category_order) {
        return a.page_order - b.page_order;
      }
      return a.category_order - b.category_order;
    });

    // Group the sorted options by category
    const groupedOptions = sortedOptions.reduce((acc, option) => {
      const category = option.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(option);
      return acc;
    }, {});

    // Convert the grouped options object into an array of groups
    return Object.values(groupedOptions);
  }, [options]);

  return (
    <div className="top-pages-nav-container">
      {navigationOptions.map((group, groupIdx) => (
        <div className={`top-pages-nav-${groupIdx ? 1 : 0}`} key={groupIdx}>
          <span className="top-pages-navigation-title">
            {group[0].category}
          </span>
          <ul>
            {group.map((option) => (
              <li key={option.name}>
                <a href={`/${option.url_key}`}>{option.name}</a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default TopPagesNavigation;
