import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FunnelChart from "../components/FunnelChart";
import topPagesService from "../../../../../services/TopPagesService";
import axios from "axios";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const LayersModal = ({
  isOpen,
  onOpen,
  selectedCard,
  onSelectedCard,
  selectedLayer,
  filters,
  topPage,
  chartLabel,
}) => {
  console.log(selectedCard);
  const detailKeyword = topPagesService.getDetailLinkFromCard(selectedCard);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    axios
      .get(`/funnels/top_pages/card_chart/${topPage}/${detailKeyword}`, {
        params: filters,
      })
      .then((response) => {
        setChartData(response.data);
      });
  }, [filters, topPage]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ outline: 0 }}
      disableAutoFocus={true}
      hideBackdrop
    >
      <Box sx={boxStyle}>
        <button
          className="close-modal-btn"
          onClick={() => {
            onSelectedCard(null);
            onOpen(false);
          }}
        >
          X
        </button>
        <Typography
          className="top-pages-modal-title"
          variant="h6"
          component="h2"
          style={{ fontSize: 30 }}
        >
          <a href={selectedCard.detail_link}>
            {selectedCard && selectedCard.title}{" "}
            <i
              className={selectedCard && selectedCard.icon}
              style={{ marginLeft: "12px" }}
            ></i>
          </a>
        </Typography>

        <div className="top-pages-modal-inner">
          <FunnelChart selectedLayer={selectedLayer} data={chartData} label={chartLabel} />
        </div>
      </Box>
    </Modal>
  );
};

export default LayersModal;
