import React, { createContext, useEffect, useState } from "react";
import optionsFetcherService from "../../../../services/OptionsFetcherService";
import axios from "axios";

const ActivityTaggingContext = createContext(null);

const ActivityTaggingProvider = ({
  children,
  activityTaggingListSalesTouchesPath,
  teamEditListActivityTagsPath,
}) => {
  const [activityTags, setActivityTags] = useState([]);
  const [loadingActivityTags, setLoadingActivityTags] = useState(false);
  const [fieldOptions, setFieldOptions] = useState({});

  useEffect(() => {
    refreshActivityTags();
    fetchOptions();
  }, []);

  const refreshActivityTags = () => {
    setLoadingActivityTags(true);
    axios
      .get(teamEditListActivityTagsPath)
      .then((response) => {
        setActivityTags(response.data);
      })
      .finally(() => {
        setLoadingActivityTags(false);
      });
  };

  const fetchOptions = () => {
    const fieldOptions = [
      { option: "subject", field: "sales_touch_subject" },
      {
        option: "task_subtype",
        field: "task_subtype",
      },
      { option: "task_type", field: "task_type" },
      { option: "created_by", field: "created_by" },
    ];

    fieldOptions.forEach((fieldOption) => {
      optionsFetcherService
        .getOptions({
          mode: "SalesTouch",
          type: "sales_touches",
          field: fieldOption.field,
        })
        .then((options) => {
          setFieldOptions((fo) => ({
            ...fo,
            [fieldOption.option]: options.filter((o) => o),
          }));
        });
    });
  };

  const autoRefreshActivityTagging = (id) => {
    let interval;
    const refresh = () => {
      axios.get(`/activity_tags/${id}/job_status.json`).then((response) => {
        console.log(response);
        const activityTag = response.data;
        if (
          activityTag["job_status"] === "Complete" ||
          activityTag["job_status"] === null
        ) {
          refreshActivityTags();
          clearInterval(interval);
        }
      });
    };

    interval = setInterval(refresh, 5000);
  };

  return (
    <ActivityTaggingContext.Provider
      value={{
        activityTags,
        loadingActivityTags,
        refreshActivityTags,
        activityTaggingListSalesTouchesPath,
        teamEditListActivityTagsPath,
        fieldOptions,
        autoRefreshActivityTagging,
      }}
    >
      {children}
    </ActivityTaggingContext.Provider>
  );
};

const useActivityTagging = () => {
  const context = React.useContext(ActivityTaggingContext);
  if (context === undefined) {
    throw new Error(
      "useActivityTagging must be used within a ActivityTaggingProvider"
    );
  }
  return context;
};

export { useActivityTagging };
export default ActivityTaggingProvider;
