import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import dateOptions from "./date_range_options.json";
import monthOptions from "./month_range_options.json";
import yearOptions from "./year_range_options.json";
import FilterSelect from "./FilterSelect";
import FilterSwitch from "./FilterSwitch";
import QueryBuilder from "../../../../components/QueryBuilder/QueryBuilder";
import axios from "axios";
import FilterDate from "./FilterDate";
import queryBuilderService from "../../../../services/QueryBuilderService";

const dateFormatFromCookies = (date) => {
  if (date) {
    const dateArray = date.split("/");
    return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
  }

  return new Date().toISOString();
};

const dateFormatToCookies = (date) => {
  if (date) {
    const dateArray = date.split("-");
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  }

  return null;
};

const getAbaCookie = () => {
  const abaCookie = Cookies.get("all_contacts");

  return abaCookie === "true" || abaCookie === true;
};

const defaultOperators = [
  { value: "equals", label: "Equals", requiresValue: true },
  { value: "does_not_equal", label: "Does Not Equals", requiresValue: true },
  { value: "contains", label: "Contains", requiresValue: true },
  {
    value: "does_not_contain",
    label: "Does Not Contain",
    requiresValue: true,
  },
  { value: "is_empty", label: "Is Empty", requiresValue: false },
  { value: "is_not_empty", label: "Is Not Empty", requiresValue: false },
];

const getLocalFilter = (filterOptions) => {
  const localFilter = Cookies.get("local_filter");

  if (localFilter) {
    try {
      return queryBuilderService.convertFromRulesParams(
        JSON.parse(localFilter).rules,
        filterOptions
      );
    } catch (e) {
      console.error(e);
      Cookies.remove("local_filter");
    }
  }
  return null;
};

const SearchFunnels = ({
  onClick,
  filterOptions,
  dateRangeLabel = "Date Range",
  isLoading,
}) => {
  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dateRange: Cookies.get("dates_date_phrase") || "this_year",
      dateRangeYear: Cookies.get("dates_year") || new Date().getFullYear(),
      dateMonthRange: Cookies.get("dates_month") || new Date().getMonth() + 1,
      attributionMode: Cookies.get("setting_mode") || "TotalEngagement",
      dateRangeStart: dateFormatFromCookies(Cookies.get("dates_custom_start")),
      dateRangeEnd: dateFormatFromCookies(Cookies.get("dates_custom_end")),
      aba: getAbaCookie(), // returns false if cookie is null/false or true otherwise,
    },
  });

  const [selectedQuery, setSelectedQuery] = useState(null);
  const [fieldOptions, setFieldOptions] = useState({});

  const [shouldShowFilter, setShouldShowFilter] = useState(false);
  const [dateRange, attributionMode] = useWatch({
    control,
    name: ["dateRange", "attributionMode"],
  });

  const fieldMappings = {
    by_month: ["dateMonthRange"],
    by_year: ["dateRangeYear"],
    custom: ["dateRangeStart", "dateRangeEnd"],
  };

  useEffect(() => {
    Object.entries(fieldMappings).forEach(([range, fields]) => {
      if (dateRange !== range) {
        fields.forEach((field) => unregister(field));
      } else {
        fields.forEach((field) => register(field));
      }
    });
  }, [register, dateRange]);

  useEffect(() => {
    if (selectedQuery) {
      const newFieldOptions = { ...fieldOptions };
      const queryString = selectedQuery.value.replaceAll(" ", "+");
      axios
        .get(`/funnels/top_pages/filter_options/${queryString}`)
        .then((response) => {
          if (response) {
            newFieldOptions[selectedQuery.value] = response.data.map(
              (option) => option.name
            );
            setFieldOptions(newFieldOptions);
          }
        });
    }
  }, [selectedQuery]);

  const handleFilters = (data) => {
    onClick(data);
  };
  const handleShowQueryBuilder = () => {
    setShouldShowFilter(!shouldShowFilter);
  };
  useEffect(() => {
    if (onClick) {
      handleSubmit(handleFilters)();
      // onClick();
    }
  }, []);

  const queryOptions = useMemo(() => {
    if (filterOptions) {
      return filterOptions.map((option) => {
        return {
          label: option.display_value,
          name: option.query_key,
          type: "text",
          operators: defaultOperators,
        };
      });
    }
    return [];
  }, [filterOptions]);

  const localFilters = useMemo(
    () => getLocalFilter(queryOptions),
    [queryOptions]
  );

  const handleQueryBuilderChange = (query) => {
    const validQueries = query.filter((rule) => {
      if (rule.requiresValue) {
        return rule.value && rule.value.length > 0;
      } else {
        if (rule.operator && rule.option) {
          return true;
        }
      }
      return false;
    });
    const jsonRules = queryBuilderService.convertToRulesParams(validQueries, {
      addField: true,
    });

    if (jsonRules.rules.length === 0) {
      Cookies.remove("local_filter");
    } else {
      Cookies.set("local_filter", JSON.stringify(jsonRules), { expires: 1 });
    }
    setValue("queryFilter", query);
  };

  return (
    <form className="controls detail-form">
      <div className="top-pages-search-container-1 controls detail-form">
        <div className="row expanded small-collapse align-left">
          <div className="columns small-12 large-2 text-left">
            <div>{dateRangeLabel}:</div>
            <FilterSelect
              options={dateOptions}
              register={register}
              onChange={(e) => {
                Cookies.set("dates_date_phrase", e.target.value, {
                  expires: 1,
                });
              }}
              registerLabel={"dateRange"}
            />
          </div>
          {dateRange === "by_month" && (
            <div className="columns small-12 large-2 daterange_picker">
              <div>Month:</div>
              <FilterSelect
                options={monthOptions}
                register={register}
                onChange={(e) => {
                  Cookies.set("dates_month", e.target.value, { expires: 1 });
                }}
                registerLabel={"dateMonthRange"}
              />
            </div>
          )}
          {dateRange === "by_year" && (
            <div className="columns small-12 large-2 daterange_picker">
              <div>Year:</div>
              <FilterSelect
                options={yearOptions}
                register={register}
                onChange={(e) => {
                  Cookies.set("dates_year", e.target.value, { expires: 1 });
                }}
                registerLabel={"dateRangeYear"}
              />
            </div>
          )}

          {dateRange === "custom" && (
            <>
              <div className="large-3 columns">
                <div>Start Date:</div>
                <FilterDate
                  register={register}
                  onChange={(e) => {
                    const formattedDate = dateFormatToCookies(e.target.value);
                    Cookies.set("dates_custom_start", formattedDate, {
                      expires: 1,
                    });
                  }}
                  registerLabel={"dateRangeStart"}
                />
              </div>

              <div className="large-3 columns">
                <div>End Date:</div>
                <FilterDate
                  register={register}
                  onChange={(e) => {
                    const formattedDate = dateFormatToCookies(e.target.value);
                    Cookies.set("dates_custom_end", formattedDate, {
                      expires: 1,
                    });
                  }}
                  registerLabel={"dateRangeEnd"}
                />
              </div>
            </>
          )}

          <div className="columns small-8 large-2 text-left">
            <FilterSwitch
              title={"Attribution Mode:"}
              options={[
                {
                  title: "Total",
                  value: "TotalEngagement",
                },
                {
                  title: "First",
                  value: "FirstTouch",
                },
                {
                  title: "Last",
                  value: "LastTouch",
                },
              ]}
              value={attributionMode}
              setValue={(value) => {
                Cookies.set("setting_mode", value, { expires: 1 });
                setValue("attributionMode", value);
              }}
            />
          </div>

          <div className="columns shrink">
            <div>ABA:</div>
            <div id="ocr_switch" className="onoffswitch">
              <input
                type="checkbox"
                className="onoffswitch-checkbox filter-select"
                id="oppty_settings_all_contacts"
                {...register("aba", {
                  onChange: (e) => {
                    Cookies.set("all_contacts", e.target.checked, {
                      expires: 1,
                    });
                  },
                })}
              />
              <label
                className="onoffswitch-label"
                htmlFor="oppty_settings_all_contacts"
              >
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          </div>

          <div className="columns small-12 large-1 shrink">
            <div>Filters:</div>
            <div
              id="filter-toggle"
              className="button tiny outline"
              title="Show Filters"
              onClick={handleShowQueryBuilder}
            >
              <i className="icon icon-funnel"></i>
            </div>
          </div>
        </div>

        <div className="row expanded align-left">
          <div style={{ display: shouldShowFilter ? "block" : "none" }}>
            <QueryBuilder
              options={queryOptions}
              onQueryChange={handleQueryBuilderChange}
              fieldOptions={fieldOptions}
              defaultQuery={localFilters}
              removeInitialRule
              onChange={(selectedFilter) => {
                setSelectedQuery(selectedFilter);
              }}
            />
          </div>
        </div>
      </div>

      <div id="action_buttons" className="row expanded small-collapse">
        <button
          type="submit"
          id="refresh_btn"
          name="search_button"
          value="search"
          className="button small primary"
          onClick={handleSubmit(handleFilters)}
        >
          <i
            className={"icon icon-refresh" + (isLoading ? " icon-spin" : "")}
          ></i>
        </button>
      </div>
    </form>
  );
};

export default SearchFunnels;
